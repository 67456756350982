import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
const App = lazy(() => import("./App"));

import reportWebVitals from "./reportWebVitals";
import AppLoader from "components/Base/Loader";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();

function Loading() {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen bg-black gap-y-5 bg-opacity-5 backdrop-blur-sm">
      <AppLoader />
      <div className="text-lg font-medium text-primaryColor">BIPAY</div>
    </div>
  );
}